@import './_variables';

@media screen and (max-width: $maxMobile) {

    .ant-select-selector,
    .ant-input,
    .ant-input-number,
    .ant-picker-input>input,
    iframe {
        font-size: 16px !important;
    }
}