@import './_variables.scss';
html,
body {
    height: 100%;
    background: $bgr;
    font-size: 16px;
    font-family: 'Abel';
}
h1 {
    font-size: 2.5rem;
    font-family: 'Abel';
}
ul,
ol {
    margin-bottom: 35px;
}
.ant-form-item.horizontal-checkbox {
    margin: 0 !important;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 10px;
    &:before {
        display: none;
    }
    > div.ant-form-item-control-wrapper {
        grid-row: 1;
    }
    > div.ant-form-item-label {
        padding: 0;
    }
}

.ant-form-item-label {
    label {
        font-size: 16px !important;
        font-family: 'Abel';
    }
}

.ant-form-item {
    margin: 10px 0 !important;
    
    .ant-upload.ant-upload-disabled ~ .ant-upload-list {
        /* Add the ant-disabled class to the second div */
        opacity: 0.5; /* Example: Adjust the opacity to visually indicate it's disabled */
        pointer-events: none; /* Disable pointer events */
    }
}

.ant-btn,
.ant-input,
.ant-picker,
.ant-select-single .ant-select-selector,
.ant-picker .ant-picker-input > input,
.ant-checkbox-wrapper,
.ant-form label,
.ant-form {
    font-size: 16px;
}

.ant-col,
.ant-select-dropdown .ant-select-item {
    font-size: 15px;
}

.ant-btn-link {
    color: $brandOne;
}

.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: #aba282;
}

.ant-btn-link:hover {
    background: transparent;
}
.ant-form-item-label > .ant-form-item-required {
    &:after {
        margin-left: 4px;
        color: #f5222d;
        font-family: Abel;
        content: '*' !important;
        display: inline-block !important;
        visibility: visible !important;
    }
    &:before {
        content: none !important;
    }
}

.custom2 span,
li {
    font-size: 16px;
}
.ptl {
    padding-top: 50px;
}
.pbl {
    padding-bottom: 50px;
}
.ptm {
    padding-top: 25px;
}
.pbm {
    padding-bottom: 30px;
}
.pbs {
    padding-bottom: 10px;
}
.pts {
    padding-top: 10px;
}
.right {
    float: right;
}
.left {
    float: left;
}
.red {
    color: $error;
}
.green {
    color: $seaGreen;
}
.text-right {
    text-align: right;
}
.bold {
    font-weight: 600;
}
.reg_h1 {
    margin-bottom: 0;
    line-height: 38px;
}
.reg_h2 {
    font-size: 22px;
    line-height: 24px;
    font-weight: bold;
}
.reg_text {
    font-size: 17px;
    line-height: 18px;
}
button {
    &.back {
        padding: 20px 0;
        font-size: 20px;
        color: $primaryColor;
        margin-bottom: 25px;
        .anticon-left {
            svg {
                width: 0.6em;
                height: 0.6em;
                vertical-align: 12%;
            }
        }
    }
    &.ant-btn-default {
        color: $brandOne;
        border-color: $brandOne;
    }
}
.ant-spin {
    &.center {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -15px;
        margin-left: -15px;
    }
}

.blur-text {
    color: $blurBlack;
}
.ant-upload-drag-container {
    padding: 25px;
}

::-webkit-scrollbar {
    width: 14px;
}
::-webkit-scrollbar-thumb {
    background-color: $brandOne;
}
::-webkit-scrollbar-track {
    background-color: $lightPagination;
}

.ant-popover {
    z-index: 199 !important;
}

#root,
.ant-modal-wrap {
    form,
    .ant-modal.so-register-modal {
        .ant-radio-group {
            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            .ant-radio-wrapper {
                flex: 1 1;
                min-height: 40px;
                padding: 10px;
                border: 1px solid #9e8e5f;
                border-radius: 5px;
                &:not(:first-of-type) {
                    margin-right: 0;
                    margin-left: 15px;
                }
                > .ant-radio-checked .ant-radio-inner::after {
                    border-spacing: 0px;
                }
                &.ant-radio-wrapper-checked {
                    background: #f5f4ef;
                }
                > span:not(.ant-radio) {
                    b {
                        display: block;
                    }
                }
            }
        }
        // .ant-input[disabled],
        .ant-select-disabled {
            color: rgba(0, 0, 0, 0.6);
        }
    }

    .ant-alert {
        margin-bottom: 30px;
    }
}

.ant-select-tree {
    text-align: left;
}

.ant-menu-vertical {
    border: none !important;
}

.ant-select-item-option-content {
    white-space: pre-wrap !important;
}

.ant-input-group-addon {
    top: 0px;
    position: inherit;
    background-color: $brandOne !important;
    width: 4em;
    color: $white;
}

input,
.ant-picker-range,
.ant-input-number,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 35px;
    font-family: 'Abel';
    border-radius: 5px;
}
.ant-picker-input {
    height: 27px;
    font-family: 'Abel';
}
.ant-input-password {
    input {
        height: 27px;
    }
}

.ant-select-selection-search-input {
    font-size: 16px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 33px;
}

@media screen and (max-width: $maxMobile) {
    .ant-select-selection {
        padding: 8px 4px 8px 0;
    }
    .btn-new-order,
    .btn-inventory-popover {
        position: fixed !important;
        z-index: 4;
        right: 25px !important;
        bottom: 100px !important;
        font-size: 17px !important;
        height: 50px !important;
    }

    .menu-icons {
        text-align: center;
    }
}
