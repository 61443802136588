@import './variables';

@media screen and (max-width: $maxMobile) {
    // #root {
    //     ::-webkit-scrollbar {
    //         display: none;
    //     }
    // }
    .order-details {
        .ant-table-row-cell-break-word {
            padding: 0 0 10px 0 !important;
        }
        .ant-table-row-cell-break-word .ant-form-item {
            padding: 0 4px 0 0 !important;
        }
    }

    .order-numbr-boxes {
        display: block;
        .ant-col-lg-18 {
            p {
                margin: 10px 0 auto 0;
            }
        }
    }
    .order-shipping-terms {
        .ant-radio-group {
            width: 100%;
            display: flex;
            flex-direction: column;
            .ant-radio-wrapper {
                width: 100% !important;
                margin: 10px 0 10px 0 !important;
                flex: none !important;
            }
        }
    }
    .order-cart-modal {
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        min-height: 100% !important;
        top: 0 !important;
        padding: 0 !important;
        display: flex;
        .ant-modal-content {
            box-shadow: none;
            padding-top: 50px;
            .ant-modal-body {
                height: 100%;
                padding: 25px 10px;
                .cart-item {
                    .cart-species {
                        font-size: 16px;
                    }
                    .cart-prices {
                        font-size: 11px;
                    }
                    .cart-secondary-text {
                        font-size: 13px;
                    }
                    .cart-total {
                        font-size: 12px;
                        white-space: nowrap;
                    }
                    .item-image {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
            width: 100%;
        }
        .ant-modal-mask {
            background-color: white !important;
        }

        .order-cart {
            padding-left: 0;
        }
        .ant-modal-close {
            top: 10px;
            right: 10px;
            border-radius: 50%;
            background-color: white;
            .ant-modal-close-icon {
                border-radius: 50%;
                border: 1px solid $brandOne;
                padding: 10px;
                svg {
                    fill: $brandOne;
                }
            }
        }
    }
    .choose-order-type {
        .ant-btn {
            width: 100%;
            height: 60px;
            font-size: 16px;
            margin: 10px 0;
        }
    }
    .order {
        .btn-new-order {
            width: 131px;
            box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.27);
            border-radius: 44px;
        }
        .order-payment {
            .ant-radio-group {
                flex-direction: column;
                .ant-radio-wrapper {
                    padding: 25px !important;
                }
                .ant-radio-wrapper:not(:first-of-type) {
                    margin-left: 0 !important;
                }
            }
            .ant-checkbox-inner {
                margin-right: 10px;
            }
        }
        .ant-checkbox-inner {
            width: 30px;
            height: 30px;
            &::after {
                width: 10px;
                height: 15px;
            }
        }
        button,
        .ant-btn-primary:not(.btn-new-order) {
            width: 100%;
            height: 50px;
            display: flex;
            span {
                margin: auto;
            }
        }
        .ant-radio-wrapper {
            width: 100%;
        }
        .ant-radio-wrapper {
            display: flex;
            height: 70px;
            span {
                margin: auto 0;
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: $smallest) {
    .order-numbr-boxes {
        display: block;
        .ant-col-lg-18 {
            p {
                margin: 55px 0 auto 0;
            }
        }
    }
}
