@import url('https://fonts.googleapis.com/css?family=Abel&display=swap');

$bgr: white;
$white: white;
$primaryColor: rgba(8, 0, 0, 1);
$lightSilver: #d6d6d6;
$lightPagination: #e5e5e5;
$blurBlack: #767676;
$seaGreen: #4cb8a8;
$gold: #9e8e5f;
$borderRadius: (
    xs: 0,
    md: 0,
    xl: 0,
);
$error: #d64d4d;
$redCloud: #ff8e8e;
$modalPadding: 24px;

$brandOne: rgba(158, 142, 95, 1);
$brandTwo: #8d9ebc;

$fontAbel: Abel;
$headerHeight: 80px;

$blockBorder: 1px rgb(214, 214, 214) solid;
$blockBgr: rgb(245, 245, 245);

$smallest: 325px;
$xxs: 375px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$maxMobile: 1024px;
$xl: 1200px;
$xxl: 1600px;